import React from 'react';

import Seo from '../components/Seo';

const Impressum = () => {
  return (
    <>
      <Seo title="Impressum" />
      <div className="impressum card" id="impressum">
        <div className="card-top">
          <h1>Impressum</h1>
        </div>
        <div className="card-content">
          <p>
            <span className="bold">
              <span className="drumup"> Drum UP</span> – Carsten Grüter
            </span>
            <br />
            Giesenkirchener Str. 71 <br />
            41238 Mönchengladbach
            <br />
            <span className="drumup"> T:</span> 0160 975 323 77
          </p>
        </div>
      </div>
    </>
  );
};

export default Impressum;
